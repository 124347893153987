import React, { useState, useCallback, useEffect } from 'react';
import { WeekNavigation } from './WeekNavigation';
import { EventList } from './EventList';
import eventsData from '../data/events.json';
import { 
  startOfWeek, 
  addWeeks, 
  subWeeks, 
  parseISO, 
  format,
  isSameWeek
} from 'date-fns';

export const WeekView = () => {
  const [events, setEvents] = useState({});
  // pin to January 20
  const [currentWeek, setCurrentWeek] = useState(new Date('2025-01-21'));
  const [filters, setFilters] = useState({
    eventType: 'ALL',
    boro: 'ALL'
  });

  // Fetch events for a specific week
  const fetchWeekEvents = useCallback((weekStart) => {
    const nextWeekStart = addWeeks(weekStart, 1);
    
    const weekEvents = eventsData
      .map(event => ({
        ...event,
        date: parseISO(`${event.date.split('/').join('-')}`)
      }))
      .filter(event => {
        return event.date >= weekStart && event.date < nextWeekStart;
      })
      .sort((a, b) => a.date - b.date);

    return weekEvents;
  }, []);

  // Prefetch and cache events for adjacent weeks
  const prefetchEvents = useCallback((week) => {
    const weekStart = startOfWeek(week, { weekStartsOn: 1 });
    const weekKey = format(weekStart, 'yyyy-MM-dd');

    if (!events[weekKey]) {
      const weekEvents = fetchWeekEvents(weekStart);
      setEvents(prev => ({
        ...prev,
        [weekKey]: weekEvents
      }));
    }

    // Prefetch next and previous weeks
    const nextWeek = addWeeks(weekStart, 1);
    const prevWeek = subWeeks(weekStart, 1);
    const nextKey = format(nextWeek, 'yyyy-MM-dd');
    const prevKey = format(prevWeek, 'yyyy-MM-dd');

    if (!events[nextKey]) {
      const nextEvents = fetchWeekEvents(nextWeek);
      setEvents(prev => ({
        ...prev,
        [nextKey]: nextEvents
      }));
    }

    if (!events[prevKey]) {
      const prevEvents = fetchWeekEvents(prevWeek);
      setEvents(prev => ({
        ...prev,
        [prevKey]: prevEvents
      }));
    }
  }, [events, fetchWeekEvents]);

  // Initialize events for current week
  useEffect(() => {
    prefetchEvents(currentWeek);
  }, [currentWeek, prefetchEvents]);

  const handleWeekChange = useCallback((newWeek) => {
    setCurrentWeek(newWeek);
  }, []);

  const getCurrentWeekEvents = useCallback(() => {
    const weekStart = startOfWeek(currentWeek, { weekStartsOn: 1 });
    const weekKey = format(weekStart, 'yyyy-MM-dd');
    return events[weekKey] || [];
  }, [currentWeek, events]);

  const filteredEvents = getCurrentWeekEvents().filter(event => {
    const typeMatch = filters.eventType === 'ALL' || event.event_type === filters.eventType;
    const boroMatch = filters.boro === 'ALL' || event.boro === filters.boro;
    return typeMatch && boroMatch;
  });

  const getWeekLabel = (date) => {
    const today = new Date();
    const nextWeek = addWeeks(today, 1);
    const lastWeek = subWeeks(today, 1);
    const weekStart = startOfWeek(date, { weekStartsOn: 1 });

    if (isSameWeek(date, today, { weekStartsOn: 1 })) {
      return 'this week';
    } else if (isSameWeek(date, nextWeek, { weekStartsOn: 1 })) {
      return 'next week';
    } else if (isSameWeek(date, lastWeek, { weekStartsOn: 1 })) {
      return 'last week';
    } else {
      return `week of ${format(weekStart, 'MMMM d, yyyy')}`;
    }
  };

  return (
    <>
      <div className="bg-rose-900/20 border border-rose-800 rounded-lg p-6 mb-8 text-center">
        <p className="text-rose-100">
          We regret to inform you that as of February 2025, it is no longer feasible to source events for POPOUT.NYC.
          <br />Thank you for your patronage. Someday we may come back stronger - for now, we slumber.
        </p>
      </div>
      
      <WeekNavigation 
        weekLabel={getWeekLabel(currentWeek)}
        onPrevious={() => handleWeekChange(subWeeks(currentWeek, 1))}
        onNext={() => handleWeekChange(addWeeks(currentWeek, 1))}
        filters={filters}
        setFilters={setFilters}
        eventCount={filteredEvents.length}
      />
      <EventList 
        events={filteredEvents} 
        currentWeek={currentWeek}
      />
    </>
  );
};

export default WeekView;
